import React from 'react';
import { useTranslation } from 'react-i18next';
import MujibcoinFlash from '../../img/coin_flashing.png';
import MetamaskIcon from '../../img/metamask.png';
import PancakeIcon from '../../img/pancake.png';
import star from '../../img/star.svg';
import star2 from '../../img/starmore.svg';
import star4 from '../../img/start_left.svg';
import star3 from '../../img/star_right.svg';
import star5 from '../../img/star_xxs.svg';
import PresellWatch from '../PresellWatch/PresellWatch';

export default function Hero() {
	const { t } = useTranslation();
	return (
		<>
			<section
				className='py-10 bg-hero-pattern bg-cover bg-center bg-no-repeat  md:flex md:items-center md:justify-center '
				id='home'
			>
				<div className='grid grid-cols-1  container mx-auto md:grid-cols-2'>
					{/* Right Text */}
					<div className='row-start-2 text-center md:text-left md:row-auto lg:mt-7 '>
						<span className='text-crypto-white text-sm md:text-base'>
							{t('headLine')}
						</span>
						<div className='mt-2'>
							<span className=' leading-10 text-crypto-white text-3xl md:text-5xl md:leading-snug md:font-semibold '>
								{t('decentralized')}{' '}
								<span className='text-crypto-yellow'>
									{t('cryptoCurrency')}
								</span>{' '}
								{t('forBd')}
							</span>
							<p className='text-crypto-white mt-4 text-sm md:text-base'>
								{t('herosubtitle')}
							</p>
						</div>
						<div className='mt-12 '>
							<a
								href='https://pancakeswap.finance/swap?outputCurrency=0xe9c393eea75e6bcF1628622cc4f55e7Db0620e80'
								target={'_blank'}
								rel='noopener noreferrer'
							>
								<button className=' btn rounded-sm  bg-crypto-yellow-light transition ease-in-out  delay-150 hover:-translate-y-1 hover:scale-110 duration-200'>
									<img className='h-8' src={PancakeIcon} alt='pancake' />
									<span className='ml-2  capitalize'>
										{' '}
										Buy Now (PancakeSwap)
									</span>
								</button>
							</a>
							<button className=' btn  border-crypto-yellow rounded-sm bg-transparent ml-4 mt-4 lg:mt-0 transition ease-in-out  delay-150 hover:-translate-y-1 hover:scale-110 duration-200 '>
								<img className='h-8' src={MetamaskIcon} alt='metamask' />
								<span className='ml-2 capitalize '>
									<a
										href='https://metamask.io/'
										target={'_blank'}
										rel='noopener noreferrer'
									>
										Metamask
									</a>
								</span>
							</button>
						</div>
					</div>
					{/* Left Image */}
					<div className='flex items-center justify-center '>
						<div className='indicator'>
							<div>
								<span className=' indicator-start'>
									<img
										className='animate-pulse transition duration-1000'
										src={star}
										alt='star'
									/>
								</span>
								<span className='indicator-item '>
									<img
										className=' transition ease-out animate-pulse duration-700'
										src={star}
										alt='star'
									/>
								</span>
								<img
									className='anim drop-yellow h-36 md:h-96'
									src={MujibcoinFlash}
									alt=''
								/>
								<span className='indicator-item indicator-bottom '>
									{' '}
									<img className='animate-pulse' src={star2} alt='star' />
								</span>
								<span className='indicator-item indicator-bottom '>
									{' '}
									<img className='animate-pulse' src={star} alt='star' />
								</span>
								<span className='indicator-item indicator-bottom indicator-start'>
									<img className='animate-ping' src={star3} alt='star' />
								</span>
								<span className='indicator-item indicator-middle '>
									{' '}
									<img
										className='animate-pulse transition delay-1000 '
										src={star5}
										alt='star'
									/>
								</span>
								<span className='indicator-item indicator-middle indicator-start'>
									{' '}
									<img className='animate-ping mt-10' src={star4} alt='star' />
								</span>
							</div>
						</div>
					</div>
					<div className='col-span-3 flex justify-center    '>
						<div className=''>
							<PresellWatch countdownTimestampMs={1661212800000} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
