import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown } from 'react-icons/hi';
import MujibLogo from '../../img/mujib_logo.png';

const changeLang = (l) => {
	return () => {
		i18n.changeLanguage(l);
		localStorage.setItem('lang', l);
	};
};
export default function Navbar() {
	const { t } = useTranslation();

	useEffect(() => {
		let currentLang = localStorage.getItem('lang');
		i18n.changeLanguage(currentLang);
	}, []);

	const [navScrolled, setnavScrolled] = useState(false);

	window.addEventListener('scroll', () =>
		window.scrollY >= 80 ? setnavScrolled(true) : setnavScrolled(false)
	);
	return (
		<>
			<nav
				className={
					navScrolled
						? 'bg-crypto-dark border-2 border-b-crypto-yellow sticky top-0 z-50'
						: 'bg-crypto-dark border-2 sticky top-0 z-50'
				}
			>
				<div className='navbar container mx-auto '>
					<div className='navbar-start'>
						<div className='dropdown '>
							<label
								tabIndex={0}
								className='btn btn-ghost text-crypto-white lg:hidden'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M4 6h16M4 12h8m-8 6h16'
									/>
								</svg>
							</label>
							<ul
								tabIndex={0}
								className='menu menu-compact text-crypto-white bg-crypto-dark  dropdown-content mt-3 p-2 shadow bg-black rounded-md w-52 '
							>
								<li className='hover:bg-crypto-dark-500 rounded-md'>
									<a href='/#'>{t('home')}</a>
								</li>
								<li className='hover:bg-crypto-dark-500 rounded-md'>
									<a href='#about'>{t('about')}</a>
								</li>
								<li className='hover:bg-crypto-dark-500 rounded-md'>
									<a href='#howToBuy'>{t('how_to_buy')}</a>
								</li>

								<li className='hover:bg-crypto-dark-500 rounded-md'>
									<a href='#supporters'>{t('supporters')}</a>
								</li>
								<li>
									<button className='bg-crypto-dark-500 hover:bg-crypto-dark btn rounded-md'>
										<a
											href='https://opensea.io/collection/untitled-collection-44157288'
											target='_blank'
											rel='noreferrer'
										>
											{'NFT'}
										</a>
									</button>
								</li>
							</ul>
						</div>
						<a href='/#'>
							<div className='ml-3'>
								<img
									className='h-16 rounded-full ring-1 ring-crypto-yellow hover:ring-2 transition ease-in-out delay-100'
									src={MujibLogo}
									alt=''
									srcSet=''
								/>
							</div>
						</a>
					</div>
					<div className='navbar-center hidden lg:flex'>
						<ul className='menu menu-horizontal p-0 text-crypto-white '>
							<li className='hover:bg-crypto-dark-500  rounded-md'>
								<a href='/#'>{t('home')}</a>
							</li>

							<li className='hover:bg-crypto-dark-500  rounded-md'>
								<a href='#about'>{t('about')}</a>
							</li>
							<li className='hover:bg-crypto-dark-500 rounded-md'>
								<a href='#howToBuy'>{t('how_to_buy')}</a>
							</li>
							<li className='hover:bg-crypto-dark-500  rounded-md'>
								<a href='#supporters'>{t('supporters')}</a>
							</li>
						</ul>
					</div>
					<div className='navbar-end'>
						<button className='bg-crypto-dark-500 hover:bg-crypto-dark btn rounded-md hidden md:block'>
							<a
								href='https://opensea.io/collection/untitled-collection-44157288'
								target='_blank'
								rel='noreferrer'
							>
								{'NFT'}
							</a>
						</button>
						<div className='dropdown'>
							<label
								tabIndex={0}
								className='btn bg-transparent hover:bg-crypto-dark-500 border-0 m-1 flex'
							>
								<span className='flex items-center justify-center'>
									<span>
										<svg
											className='inline-block h-4 w-4 fill-current md:h-5 md:w-5'
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											viewBox='0 0 512 512'
										>
											<path d='M363,176,246,464h47.24l24.49-58h90.54l24.49,58H480ZM336.31,362,363,279.85,389.69,362Z'></path>
											<path d='M272,320c-.25-.19-20.59-15.77-45.42-42.67,39.58-53.64,62-114.61,71.15-143.33H352V90H214V48H170V90H32v44H251.25c-9.52,26.95-27.05,69.5-53.79,108.36-32.68-43.44-47.14-75.88-47.33-76.22L143,152l-38,22,6.87,13.86c.89,1.56,17.19,37.9,54.71,86.57.92,1.21,1.85,2.39,2.78,3.57-49.72,56.86-89.15,79.09-89.66,79.47L64,368l23,36,19.3-11.47c2.2-1.67,41.33-24,92-80.78,24.52,26.28,43.22,40.83,44.3,41.67L255,362Z'></path>
										</svg>
									</span>
									<HiChevronDown />
								</span>
							</label>
							<ul
								tabIndex={0}
								className='dropdown-content menu shadow bg-crypto-dark rounded-md w-52 text-crypto-white font-light text-sm'
							>
								<li
									className='hover:bg-crypto-dark-500  rounded-md '
									onClick={changeLang('en')}
								>
									<span>English</span>
								</li>
								<li
									className='hover:bg-crypto-dark-500 rounded-md'
									onClick={changeLang('de')}
								>
									<span>বাংলা</span>
								</li>
							</ul>
						</div>
						<a
							href='https://metamask.io/'
							target={'_blank'}
							rel='noopener noreferrer'
							className=' capitalize btn rounded-md bg-crypto-yellow text-crypto-white hover:bg-crypto-yellow-light '
						>
							{t('connect_wallet')}
						</a>
					</div>
				</div>
			</nav>
		</>
	);
}
