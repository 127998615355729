import React, { Component } from 'react';
import Slider from 'react-slick';
import BlockChain from '../../img/supporters/blockchain.webp';
import Coinmarkt from '../../img/supporters/coinwebMarket.webp';
import ComArrow from '../../img/supporters/comarrow.webp';
import CryptoMarket from '../../img/supporters/crypto market.webp';
import ExchangeCircle from '../../img/supporters/exchange circle.webp';
import Smartnet from '../../img/supporters/smartnet.webp';
import SystemFile from '../../img/supporters/systefile.webp';

export default class AutoPlay extends Component {
	render() {
		const settings = {
			arrows: false,
			infinite: true,
			slidesToShow: 6,
			autoplay: true,
			speed: 4000,
			autoplaySpeed: 2000,
			cssEase: 'linear',
			focusOnSelect: true,
		};
		return (
			<div>
				<Slider {...settings}>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={BlockChain} alt='sliderImage' />
						</div>
					</div>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={CryptoMarket} alt='sliderImage' />
						</div>
					</div>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={ComArrow} alt='sliderImage' />
						</div>
					</div>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={ExchangeCircle} alt='sliderImage' />
						</div>
					</div>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={Smartnet} alt='sliderImage' />
						</div>
					</div>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={SystemFile} alt='sliderImage' />
						</div>
					</div>
					<div className='avatar'>
						<div className='w-14 md:w-24 rounded'>
							<img src={Coinmarkt} alt='sliderImage' />
						</div>
					</div>
				</Slider>
			</div>
		);
	}
}
