import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRemainingTimeUntilMsTimestamp } from './Utils/TimerUtils';

const defaultRemainingTime = {
	seconds: '00',
	minutes: '00',
	hours: '00',
	days: '00',
};

export default function PresellWatch({ countdownTimestampMs }) {
	const { t } = useTranslation();

	const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
	useEffect(() => {
		const intervalId = setInterval(() => {
			updateRemainingTime(countdownTimestampMs);
		}, 1000);
		return () => clearInterval(intervalId);
	}, [countdownTimestampMs]);

	function updateRemainingTime(countdown) {
		setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
	}
	return (
		<>
			<section className='py-10'>
				<div className='grid grid-flow-col gap-5 text-center auto-cols-max justify-center '>
					<div className='gap-4 px-5 py-4 md:px-20 md:py-10 text-crypto-white rounded bg-gradient-to-t from-crypto-dark-500 via-crypto-blue-500 to-crypto-dark-500'>
						<div>
							<span className='text-center text-2xl font-medium  text-crypto-white'>
								{t('watchTitle')}
							</span>
						</div>
						<div className='flex mt-3'>
							<div className='flex flex-col p-2'>
								<span className=' font-mono font-semibold text-3xl md:text-5xl'>
									<span>{remainingTime.days}</span>
									<span className='font-thin ml-4'>:</span>
								</span>
								<span className='mr-8'>{t('day')}</span>
							</div>
							<div className='flex flex-col p-2'>
								<span className='font-mono font-semibold text-3xl md:text-5xl'>
									<span>{remainingTime.hours}</span>
									<span className='font-thin ml-4'>:</span>
								</span>
								<span className='mr-8'>{t('hour')}</span>
							</div>
							<div className='flex flex-col p-2'>
								<span className='font-mono font-semibold text-3xl md:text-5xl'>
									<span>{remainingTime.minutes}</span>
									<span className='font-thin ml-4'>:</span>
								</span>
								<span className='mr-8'>{t('min')}</span>
							</div>
							<div className='flex flex-col p-2'>
								<span className='font-mono font-semibold text-3xl md:text-5xl'>
									<span>{remainingTime.seconds}</span>
								</span>
								<span className=''>{t('sec')}</span>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
