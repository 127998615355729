import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import App from './App';
import './index.css';
import tDe from './locales/de/transaltion.json';
import tEn from './locales/en/transaltion.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: tEn,
		},
		de: {
			translation: tDe,
		},
	},
	lng: 'en',
	fallbackLng: 'en',

	interpolation: {
		escapeValue: false,
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
