import React, { useState } from 'react';
import { FaAngleUp } from 'react-icons/fa';

const ScrollButton = () => {
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	window.addEventListener('scroll', toggleVisible);

	return (
		<button>
			<FaAngleUp
				onClick={scrollToTop}
				className='btn bg-transparent text-5xl fixed bottom-3 right-3 z-10 cursor-pointer text-crypto-yellow  border-crypto-blue-500 p-2 border-2 border-solid rounded-md hover:border-crypto-yellow'
				style={{ display: visible ? 'inline' : 'none' }}
			/>
		</button>
	);
};

export default ScrollButton;
