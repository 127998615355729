import { useTranslation } from 'react-i18next';
import { FaClipboardList } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const BuyingMethods = () => {
	const { t } = useTranslation();
	return (
		<section className='py-10 bg-buy-Bg bg-cover bg-fixed' id='howToBuy'>
			<div className='container mx-auto'>
				<div className='flex flex-col justify-center items-center mb-5'>
					<h2 className='font-semibold text-3xl md:text-5xl text-crypto-white text-center'>
						{t('how_to_buy')}
					</h2>
					<p className='text-crypto-white my-3'>{t('contact_address')}</p>

					<div className='flex bg-crypto-dark-500  px-2 py-2 rounded-md'>
						<ToastContainer
							position='bottom-left'
							autoClose={3000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover={false}
						/>
						<FaClipboardList
							data-bs-toggle='tooltip'
							data-bs-placement='top'
							title='copy address'
							onClick={() => {
								toast.success('Address copied to clipboard', {
									position: toast.POSITION.BOTTOM_LEFT,
								});
								navigator.clipboard.writeText(
									'0xe9c393eea75e6bcF1628622cc4f55e7Db0620e80'
								);
							}}
							className='p-1 mx-2 text-3xl text-crypto-dark hover:bg-crypto-blue-500 cursor-pointer tooltip rounded-md'
						/>
						<p className=' text-crypto-white sm:text-base md:text-lg'>
							0xe9c393eea75e......cc4f55e7Db0620e80
						</p>
					</div>
				</div>
				<div className='grid md:grid-rows-4 place-items-center'>
					<div>
						{' '}
						<div className='indicator my-3'>
							<span className='indicator-item indicator-start text-5xl text-crypto-yellow ml-8'>
								1
							</span>
							<div className='card md:w-96 h-44 sm:w-auto bg-crypto-dark shadow-xl rounded-md'>
								<div className='card-body'>
									<h2 className='font-semibold text-crypto-white'>
										{t('metamask_download')}
									</h2>
									<p className='text-crypto-white text-sm'>
										{t('metamask_des')}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div>
						{' '}
						<div className='indicator my-3 lg:ml-56'>
							<span className='indicator-item indicator-start text-5xl text-crypto-yellow ml-8'>
								2
							</span>
							<div className='card md:w-96 h-44 sm:w-auto bg-crypto-dark shadow-xl rounded-md'>
								<div className='card-body'>
									<h2 className='font-semibold text-crypto-white'>
										{t('bnb_meta')}
									</h2>
									<p className='text-crypto-white text-sm'>{t('bnb_des')}. </p>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className='indicator my-3 lg:ml-28'>
							<span className='indicator-item indicator-start text-5xl text-crypto-yellow ml-8'>
								3
							</span>
							<div className='card md:w-96 h-44 sm:w-auto bg-crypto-dark shadow-xl rounded-md'>
								<div className='card-body'>
									<h2 className='font-semibold text-crypto-white'>
										{t('paneCake')}
									</h2>
									<p className='text-crypto-white text-sm'>
										{t('paneCake_des')}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className='indicator my-3 lg:ml-96'>
							<span className='indicator-item indicator-start text-5xl text-crypto-yellow ml-8'>
								4
							</span>
							<div className='card md:w-96 h-44 sm:w-auto bg-crypto-dark shadow-xl rounded-md'>
								<div className='card-body'>
									<h2 className='font-semibold text-crypto-white'>
										{t('swap_bnb')}
									</h2>
									<p className='text-crypto-white text-sm'>
										{t('swap_bnb_des')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* </div> */}
				{/* <div className='row-start-1 lg:row-auto bg-crypto-yellow-light'>
						<div className='flex flex-col justify-center items-center'>
							<h2 className='font-semibold text-3xl md:text-5xl text-crypto-white text-center'>
								HOW TO BUY
							</h2>
							<p className='text-crypto-white mt-3'>
								MujibCoin contact address
							</p>
							<div className='flex bg-crypto-dark-500  px-2 py-2 rounded-md'>
								<FaClipboardList className='mx-2 text-2xl text-crypto-yellow' />
								<p className=' text-crypto-white sm:text-base md:text-lg'>
									0xe9c393eea75e6bcF1628622cc4f55e7Db0620e80
								</p>
							</div>
						</div>
					</div> */}
				{/* </div> */}
			</div>
		</section>
	);
};

export default BuyingMethods;
