import { useTranslation } from 'react-i18next';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import WordMap from '../../img/worldmap.svg';

const Testimonial = () => {
	const { t } = useTranslation();
	return (
		<section className='py-10' id='testmonial'>
			<div className='container mx-auto'>
				<div className='grid lg:grid-cols-2 items-center'>
					<div>
						<img className='w-full' src={WordMap} alt='' />
					</div>

					<div>
						<div>
							<div className='flex justify-start'>
								<FaQuoteLeft className='text-crypto-yellow' />
							</div>
							<h2 className='text-3xl text-crypto-white w-11/12 text-center font-semibold'>
								{t('creator_speech')}
							</h2>
							<p className='text-crypto-white text-right mr-14'>
								{t('creator')}
							</p>
							<div className='flex justify-end'>
								<FaQuoteRight className='text-crypto-yellow' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Testimonial;
