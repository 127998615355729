import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdLockOutline, MdStackedBarChart, MdTrendingUp } from 'react-icons/md';
import docFile from '../../Doc/Digital-bangladesh-coin.pdf';
import CryptoNet from '../../img/crypto_net.svg';

export default function About() {
	const { t } = useTranslation();
	return (
		<>
			{/* About Section  start*/}
			<section className='aboutSection py-10 bg-crypto-dark' id='about'>
				{/* Main Grid Start */}
				<div className=' sm:container mx-auto  grid md:grid-cols-2'>
					{/* column 1 */}
					<div className='text-crypto-white aboutLeft  flex items-center'>
						<div className='leftWrapper   text-center md:text-justify row-start-2 md:row-sauto'>
							<div className='aboutTitle '>
								<span className='bg-crypto-yellow px-3 py-1 font-semibold text-sm rounded-sm'>
									{t('about')}
								</span>
							</div>
							<div className='subHeadDetails mt-2'>
								<p className='text-2xl  md:text-4xl font-bold leading-normal'>
									{t('about_title_1')} <br />
									<span className='text-crypto-yellow'>{t('about_title_2')}</span>
								</p>
								<p className='leading-loose text-center md:text-justify mt-2'>
									{t('about_des')}
								</p>
							</div>
							<div className='listItem mt-3 flex justify-center md:justify-start'>
								<ul>
									<li className='flex items-center p-1 '>
										<span className='text-crypto-yellow text-xl'>
											<MdTrendingUp />
										</span>
										<span className='ml-2 font-semibold'>
											{t('secure_payments')}
										</span>
									</li>
									<li className='flex items-center p-1'>
										<span className='text-crypto-yellow text-xl'>
											<MdLockOutline />
										</span>
										<span className='ml-2 font-semibold'>{t('top_trading')}</span>
									</li>
									<li className='flex items-center p-1'>
										<span className='text-crypto-yellow text-xl'>
											<MdStackedBarChart />
										</span>
										<span className='ml-2 font-semibold'>
											{t('easy')}
										</span>
									</li>
									<li className='flex items-center p-1 '>
										<span className='text-crypto-yellow text-xl'>
											<MdTrendingUp />
										</span>
										<span className='ml-2 font-semibold'>
											{t('top_trading')}
										</span>
									</li>
									<li className='flex items-center p-1'>
										<span className='text-crypto-yellow text-xl'>
											<MdLockOutline />
										</span>
										<span className='ml-2 font-semibold'>{t('secure_payments')}</span>
									</li>
									<li className='flex items-center p-1 '>
										<button className=' btn  border-crypto-yellow rounded-sm bg-transparent mt-4 transition ease-in-out  delay-150 hover:-translate-y-1 hover:scale-110 duration-200 '>
											<span className='ml-2 capitalize '>
												<a href={docFile} download>
													{t('readMore')}
												</a>
											</span>
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{/* END Column 1 */}

					{/* Column 2 */}
					<div className='aboutRight row-start-1 md:row-auto'>
						<div className='flex items-center justify-center'>
							<img className='w-full' src={CryptoNet} alt='cryptocoinImage' />
						</div>
					</div>
					{/* Column 2 end */}
				</div>
				{/* Main Grid End */}
			</section>
			{/* About End */}
		</>
	);
}
