import React from 'react';
import { useTranslation } from 'react-i18next';
import AutoPlay from './Autoplay';

export default function Supporters() {
	const { t } = useTranslation();
	return (
		<>
			<section className='supporters py-10 container mx-auto' id='supporters'>
				{/* Title */}
				<div className='flex justify-center'>
					<span className='bg-crypto-yellow px-3 text-crypto-white py-1 font-semibold text-sm rounded-sm '>
						{t('supporters')}
					</span>
				</div>
				{/* Subtitle */}
				<div className='flex justify-center mt-3'>
					<span className=' text-2xl md:text-4xl text-crypto-white'>
						{t('support')}
					</span>
				</div>
				{/* D E V I D E R */}
				<hr className='mt-5 text-crypto-white' />
				{/* Slider Area */}
				<div className='mt-4'>
					<AutoPlay />
				</div>
			</section>
		</>
	);
}
