import About from './Components/About Us/About';
import BackToTop from './Components/BackToTop/BackToTop';
import BuyingMethods from './Components/BuyingMethods/BuyingMethods';
import Features from './Components/Features/Features';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero/Hero';
import Navbar from './Components/Navbar/Navbar';
import Supporters from './Components/Supporters/Supporters';
import Testimonial from './Components/Testimonial/Testimonial';

function App() {
	return (
		<>
			<Navbar />
			<Hero />
			<Features />
			<About />
			<BuyingMethods />
			<Testimonial />
			<Supporters />
			<BackToTop />
			<Footer />
		</>
	);
}

export default App;
