import { useTranslation } from 'react-i18next';
import CoinMarge from '../../img/coin_marge.png';
import IncreaseIcon from '../../img/increase.png';
import SecureIcon from '../../img/secure.png';

const Features = () => {
	const { t } = useTranslation();
	return (
		<section className='px-4 pt-4 pb-10 mx-auto bg-crypto-dark-500 text-crypto-white'>
			<div className='container mx-auto'>
				<h2 className='text-center text-4xl font-semibold pt-4'>
					{t('feature_Title')}
				</h2>
				<p className='text-center mt-3'>{t('feature_subtitle')}</p>

				<div className='grid gap-8 md:grid-cols-2 lg:grid-cols-3 mt-20'>
					<div className='flex item-center justify-center flex-col group cursor-pointer'>
						<img
							className='group-hover:group-hover:animate-hovering w-44 -mb-12 z-10 mx-auto drop-blue'
							src={SecureIcon}
							alt='secured icon'
						/>

						<div className='card w-auto shadow-sm p-10 mx-auto bg-gradient-to-r from-crypto-dark-500 via-crypto-blue-500 to-crypto-dark-500'>
							<h3 className='text-center font-bold text-xl mb-5 mt-5'>
								{t('feature_Box_Title')}
							</h3>
							<p className='text-center'>{t('feature_Box_Des')}</p>
						</div>
					</div>
					<div className='flex item-center justify-center flex-col group cursor-pointer'>
						<img
							className='w-44 -mb-12 z-10 mx-auto drop-purple group-hover:group-hover:animate-hovering'
							src={IncreaseIcon}
							alt='secured icon'
						/>

						<div className='card w-auto shadow-sm p-10 mx-auto bg-gradient-to-r from-crypto-dark-500 via-crypto-blue-500 to-crypto-dark-500'>
							<h3 className='text-center font-bold text-xl mb-5 mt-5'>
								{t('feature_Box_Title_2')}
							</h3>
							<p className='text-center'>{t('feature_Box_Des_2')}</p>
						</div>
					</div>
					<div className='flex item-center justify-center flex-col group cursor-pointer'>
						<img
							className='w-44 -mb-12 z-10 mx-auto drop-yellow group-hover:group-hover:animate-hovering'
							src={CoinMarge}
							alt='secured icon'
						/>

						<div className='card w-auto shadow-sm p-10 mx-auto bg-gradient-to-r from-crypto-dark-500 via-crypto-blue-500 to-crypto-dark-500'>
							<h3 className='text-center font-bold text-xl mb-5 mt-5'>
								{t('feature_Box_Title_3')}
							</h3>
							<p className='text-center'>{t('feature_Box_Des_3')}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Features;
