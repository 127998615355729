import { useTranslation } from 'react-i18next';
import {
	FaFacebook,
	FaInstagram,
	FaTwitterSquare,
	FaYoutube,
} from 'react-icons/fa';
import MujibLogo from '../../img/mujib_logo.png';

const Footer = () => {
	const { t } = useTranslation();
	return (
		<>
			<section className='bg-crypto-dark-500 py-10'>
				<footer>
					<div className='container mx-auto'>
						<div className='grid gap-8 lg:grid-cols-3 items-center'>
							<div className='flex mx-auto lg:mx-0'>
								<a href='https://www.facebook.com/cryptomujibcoin/'>
									<FaFacebook className='text-crypto-white text-2xl mx-2' />
								</a>
								<a href='https://mobile.twitter.com/MujibCoin'>
									<FaTwitterSquare className='text-crypto-white text-2xl mx-2' />
								</a>
								<a href='https://instagram.com/mujibcoin?r=nametag'>
									<FaInstagram className='text-crypto-white text-2xl mx-2' />
								</a>
								<a href='https://youtube.com/c/RezviSultana'>
									<FaYoutube className='text-crypto-white text-2xl mx-2' />
								</a>
							</div>
							<div className='mx-auto row-start-1 lg:row-auto'>
								<a href='/#'>
									<img
										className='h-16 rounded-full ring-1 ring-crypto-yellow hover:ring-2 transition ease-in-out delay-100'
										src={MujibLogo}
										alt='logo'
									/>
								</a>
							</div>
							<div className='mx-auto'>
								<div className='text-center'>
									<div className='text-crypto-white font-medium'>
										<p>
											{t('footer_text_1')} {new Date().getFullYear()} |{' '}
											<span className='inline-block'>
												{' '}
												{t('footer_text_2')}
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</section>
		</>
	);
};

export default Footer;
